import React from "react";
import {applicationName} from "../../util/config";
import "./style.scss";
import {ImprintButtons} from "./ImprintButtons";

export function Privacy() {

    // https://www.gdprprivacypolicy.net/download.php?lang=en&token=aFbUYorWEjNBJ5G93JdxLFXvZl6B2ssC#
    return (
        <div id={"privacy"}>
            <div className={"contentWrapper"}>
                <ImprintButtons />
                <h1>Privacy Policy for {applicationName}</h1>

                <p>
                    At Yacht.Chat, accessible from https://www.yacht.chat, one of our main priorities is the privacy of
                    our
                    visitors. Within the use of the website yacht.chat and/or the use of its video chat-platform
                    “app.yacht.chat”
                    (hereinafter also referred to as “services”), we, as the data controller, collect and store the data
                    you
                    provided as long and so far this is necessary to fulfill the specified purposes and legal
                    obligations.
                </p>
                <p>
                    In the following, we will inform you what data is involved, how the data is processed and what
                    rights
                    you have in this regard.
                </p>

                <p>
                    Yacht.Chat legal basis for collecting and using the personal information described in this Privacy
                    Policy depends on the Personal Information we collect and the specific context in which we collect
                    the
                    information:

                    <li>Yacht.Chat needs to perform a contract with you</li>
                    <li>You have given Yacht.Chat permission to do so</li>
                    <li>Processing your personal information is in Yacht.Chat legitimate interests</li>
                    <li>Yacht.Chat needs to comply with the law</li>
                </p>

                <p>
                    According to Article 4 no. 1 General Data Protection Regulation (GDPR) personal data means any
                    information relating to an identified or identifiable natural person (in the following „person
                    concerned
                    or user“).
                </p>

                <p>If you have additional questions or require more information about our Privacy Policy, do not
                    hesitate to
                    contact us.
                </p>

                <h2>Name and contact data of the data controller</h2>

                <p>
                    This Data Protection Policy covers data processing on the website www.yacht.chat. Email:
                    <a href={"mailto:info@yacht.chat"}>info@yacht.chat</a>.
                </p>

                <p>
                    The Data protection officer of Yacht.Chat can be reached via e-Mail to <a
                    href={"mailto:info@yacht.chat"}>info@yacht.chat</a>. You can always
                    reach out to our data protection officer directly if you have any questions about data protection
                    law or
                    about your rights as a data subject.
                </p>

                <h2>Privacy Policy of {applicationName}</h2>

                <p>{applicationName} operates the www.yacht.chat website, which provides the SERVICE.</p>

                <p>
                    This page is used to inform website visitors regarding our policies with
                    the collection, use, and disclosure of Personal Information if anyone decided
                    to use our Service, the <a href={"https://www.yacht.chat"}>www.yacht.chat</a> website.
                </p>

                <p>
                    If you choose to use our Service, then you agree to the collection and use
                    of information in relation to this policy. The Personal Information that we
                    collect is used for providing and improving the Service. We will not use or
                    share your information with anyone except as described in this Privacy Policy.
                </p>

                <p>
                    The terms used in this Privacy Policy have the same meanings as in our
                    Terms and Conditions, which are accessible at <a
                    href="https://www.yacht.chat">www.yacht.chat</a> and
                    <a href={"https://app.yacht.chat"}>app.yacht.chat</a>, unless otherwise defined in this Privacy
                    Policy.
                </p>

                <h3>Information Collection and Use</h3>

                <p>
                    For a better experience, while using our Service, we may require you to
                    provide us with certain personally identifiable information, including but not
                    limited to your name and email address. We process your data (especially the
                    information you provide to us or your user data) exclusively on the specified
                    legal basis (Art. 6 DSGVO) and therefore might use service providers if indicated.
                </p>

                <h3>Webhosting</h3>

                <p>
                    The offer of the website requires the commissioning of a web hosting service.
                </p>

                <p>
                    For the provision of this website and its service, we use the web hosting
                    service Google Cloud Service 1600 Amphitheatre Parkway Mountain View, CA 94043,
                    Europe-west 3. The Google server location is Frankfurt am Main, Europe. Thus, Google
                    ensures that the data is not transmitted to the USA. In addition, Google Cloud Service
                    is certified by reliable security standards, including ISO 27001, SOC 1/2/2 and
                    PCI DSS Level 1.
                </p>

                <h3>Using our Services</h3>

                <p>
                    When using our services we will collect and store your data to the
                    following extent, depending on which service you use. We hereby might use the services
                    of a service provider, if indicated.
                </p>

                <h3>When setting up a user account</h3>

                <p>
                    You can set up a password-protected user account with us in which we save
                    your personal data. The purpose of this is to provide you with the greatest
                    possible comfort through easier, faster and more personal use of the website
                    and its services.
                </p>

                <p>
                    If you would like to set up a password-protected user account with us, we
                    need the following information from you:
                    <li>First and last name,</li>
                    <li>a valid email address.</li>
                </p>


                <p>
                    In addition, to open a user account you have to enter a password of your
                    choice. Together with your email address this provides access to your user
                    account.
                </p>

                <p>
                    Furthermore, we ask you to provide us with a picture of you to be displayed
                    to other users within the digital space of yacht.chat.
                </p>

                <p>
                    The provision of this data is voluntary.
                </p>

                <p>
                    After your user account is deleted, your personal data is automatically
                    deleted, unless we are obliged to longer storage under legal documentation
                    duties or you have consented to a prolonged period under Art. 6 Subs. 1
                    Sentence 1 lit. a GDPR.
                </p>
                <p>
                    We send automated confirmation emails on the legal basis of Art. 6 Subs. 1
                    Sentence 1 lit. a DSGVO using the Zoho Mail service from Zoho Corporation, 140
                    &amp; 151, GST Road, Vallancherry Village, Chengalpattu Taluk, Kanchipuram
                    District 603 202 India (“Zoho”). The email addresses of our email recipients
                    are stored on Zoho's servers in Europe on our behalf.
                </p>

                <p>
                    Zoho uses this
                    information to send and evaluate automated emails on our behalf. We have
                    concluded a Data Protection Agreement with MailChimp for this purpose. Through
                    this contract, MailChimp assures that they process the data in accordance with
                    the General Data Protection Regulation and ensure the protection of the rights
                    of the data subject. Zoho assures that personal data is comprehensively
                    protected against unauthorized access. Zoho itself does not use the data of our
                    email recipients to write to them itself or pass the data on to third parties.
                </p>

                <h3>Transfer of Data</h3>

                <p>
                    Your personal data will not be transferred to third parties for purposes
                    other than those specified below.
                </p>

                <p>
                    To the extent that this is legally permitted and according to Art. 6 Subs. 1 S. 1 lit. b GDPR
                    necessary
                    for executing contractual
                    relationships with you your personal data may be communicated to third parties.
                    This covers the transfer to provide IT services. The transferred data may be
                    used by the third-party exclusively for the stated purposes.
                </p>

                <h3>Log Data</h3>

                <p>
                    We want to inform you that whenever you visit our Service, we collect
                    information that your browser sends to us which is called Log Data. This Log
                    Data may include information such as your computer's Internet Protocol (“IP”)
                    address, browser version, pages of our Service that you visit, the time and
                    date of your visit, the time spent on those pages, and other statistics.
                </p>

                <h3>Cookies</h3>

                <p>
                    Cookies are files with small amount of data that is commonly used as an
                    anonymous unique identifier. These are sent to your browser from the website
                    that you visit and are stored on your computer's hard drive.
                </p>

                <p>
                    Our website uses these “cookies” to collect information and to improve
                    our Service. You have the option to either accept or refuse these cookies and
                    know when a cookie is being sent to your computer. If you choose to refuse our
                    cookies, you may not be able to use some portions of our Service.
                </p>

                <h3>Consent-Management-Tool</h3>

                <p>
                    We use the consent management
                    service Posthog of PostHog Inc, 2261 Market Street Unit 4008 San Francisco, CA
                    94114 United States on our website. In this context, the date and time of the
                    visit, browser information, consent information, device information and the IP
                    address of the requesting device are processed. Obtaining and managing legally
                    required consents is to be considered a legitimate interest in the sense of the
                    aforementioned provision, as the interference with the rights of users as a
                    result of the use of anonymized IP addresses and the involvement of a self-hosted
                    service is very low. PostHog does not store any data, instead, consents
                    and revocations are stored by Yacht.chat. The legal basis is our legitimate
                    interest according to Art. 6 para. 1 p. 1. lit. f DSGVO. Being able to comply
                    with the accountability obligation pursuant to Art. 5 (2) DSGVO is a legitimate
                    interest too.
                </p>

                <p>
                    Any tracking and targeting measures which we use are carried out on the basis of Art. 6 Subs. 1
                    Sentence
                    1 lit. a GDPR (consent).
                </p>

                <p>
                    With the deployed tracking
                    measures we want to ensure an appropriate design and continuous optimization of
                    our website. On the other hand, we use tracking measures to compile statistics
                    on the use of our website and to evaluate the optimization of our offerings for
                    you.
                </p>

                <p>
                    These interests are to be
                    considered as justified within the meaning of the aforementioned regulation.
                </p>

                <p>
                    The pertinent data processing
                    purposes and data categories can be found in the corresponding tracking and
                    targeting tools.
                </p>

                <h3>Service Providers</h3>

                <p>
                    We do not employ third-party companies and individuals other than the instances mentioned above.
                </p>

                <h3>Security</h3>

                <p>
                    We value your trust in providing us your Personal Information, thus we are
                    striving to use commercially acceptable means of protecting it. But remember
                    that no method of transmission over the internet, or method of electronic
                    storage is 100% secure and reliable, and we cannot guarantee its absolute
                    security.
                </p>

                <h3>Links to Other Sites</h3>

                <p>
                    Our Service may contain links to other sites. If you click on a third-party
                    link, you will be directed to that site. Note that these external sites are not
                    operated by us. Therefore, we strongly advise you to review the Privacy Policy
                    of these websites. We have no control over and assume no responsibility for
                    the content, privacy policies, or practices of any third-party sites or
                    services.
                </p>

                <h3>Children's Privacy</h3>

                <p>
                    Our Services do not address anyone under the age of 13. We do not knowingly
                    collect personally identifiable information from children under 13. In the case
                    we discover that a child under 13 has provided us with personal information, we
                    immediately delete this from our servers. If you are a parent or guardian and
                    you are aware that your child has provided us with personal information, please
                    contact us so that we will be able to do the necessary actions.
                </p>

                <h3>Data subject rights</h3>

                <p>
                    You have the right:

                    <li>
                        pursuant to Art. 7 Subs. 3 GDPR to withdraw your consent to us at any time.
                        This means that we may no longer continue processing the data based on that
                        consent for the future;
                    </li>

                    <li>
                        pursuant to Art. 15 GDPR to demand information about your personal data we
                        process. In particular, you can demand information about the purposes of the
                        processing, the category of the personal data, the categories of recipients to
                        whom your data were or will be disclosed, the planned storage period, the
                        existence of a right to rectification, deletion, restriction or revocation of
                        processing, the existence a right to lodge a complaint, the origin of your
                        data, in so far as not collected by us, and also about the existence of
                        automated decision-making including profiling and where appropriate meaningful
                        information about to details thereof;
                    </li>

                    <li>
                        pursuant to Art. 16 GDPR to demand immediate rectification of inaccurate or
                        completion of your personal data saved with us;
                    </li>

                    <li>
                        pursuant to Art. 17 GDPR to demand the deletion of your personal data saved
                        with us, in so far as the processing is not required for exercising the right
                        of freedom of expression and information, to comply with a legal obligation,
                        for reasons of public interest or to establish, exercise or defend legal
                        claims;
                    </li>

                    <li>
                        pursuant to Art. 18 GDPR to demand restriction of processing of your
                        personal data, in so far as you contest the accuracy of the data, the
                        processing is unlawful but you oppose deletion and we no longer need the data
                        but you do to establish, exercise or defend legal claims or you have objected
                        to processing pursuant to Art. 21 GDPR;
                    </li>

                    <li>
                        pursuant to Art. 20 GDPR to receive your personal data you have provided us
                        in a structured, commonly used and machine-readable format or to demand
                        transmission to another controller;
                    </li>

                    <li>
                        pursuant to Art. 77 GDPR to lodge a complaint to a supervisory authority.
                        As a rule, you can contact the supervisory authority for your habitual
                        residence or place of work or our registered offices.
                    </li>
                </p>


                <h3>Right to object pursuant to Art. 21 GDPR</h3>

                <p>
                    In so far as your personal data is processed on the basis of legitimate
                    interests pursuant to Art. 6 Subs. 1 Sentence 1 lit. e and Art. 6 Subs. 1
                    Sentence 1 lit. f GDPR, you have the right, pursuant to Art. 21 GDPR, to object
                    to the processing of your personal data, insofar as there are grounds arising
                    from your particular situation or it relates to objection to direct marketing.
                    In the latter case, you have a general right to object which we shall heed
                    without the stating of a particular situation; This also applies to profiling
                    based on those provisions as defined in Art. 4 no 4 GDPR.
                </p>

                <p>
                    If you submit an objection we shall no longer process your personal data
                    unless we can demonstrate compelling legitimate grounds for the processing
                    which override your interests, rights and freedoms or the processing is
                    necessary for the establishment, exercise, or defense of legal claims.
                </p>

                <p>
                    If your objection is to the processing of data for direct marketing
                    purposes, we shall cease processing immediately. In this case, it is not
                    necessary for you to assert a particular situation. This also applies to
                    profiling to the extent that it is related to such direct marketing.</p>

                <p>
                    If you want to exercise your right to object, simply send an email to <a
                    href={"mailto:info@yacht.chat"}>info@yacht.chat</a>.
                </p>

                <h2>Changes to This Privacy Policy</h2>

                <p>
                    We may update our Privacy Policy from time to time. Thus, we advise you to review
                    this page periodically for any changes. We will notify you of any changes by
                    posting the new Privacy Policy on this page. These changes are effective
                    immediately after they are posted on this page.
                </p>

                <h2>Contact Us</h2>

                <p>If you have any questions or suggestions about our Privacy Policy, do not
                    hesitate to contact us.
                </p>

                <p>Last updated: 29.11.2021</p>
            </div>
        </div>

    )
}