import React from "react";

export function Separator() {
    return (
        <svg className={"separator"} width="100%" height="100%" viewBox="0 0 3544 591" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5,590.551C0,590.551 723.839,295.276 1771.65,295.276C2819.47,295.276 3543.31,590.551 3543.31,590.551L0,590.551Z"/>
        </svg>
        // <svg className={"separator"} width="200%" height="100%" viewBox="0 0 1181 178" version="1.1"
        //      style={{fillRule: "evenodd", clipRule: "evenodd", strokeLinejoin: "round", strokeMiterlimit: 2}}>
        //     <g transform="matrix(0.268213,0,0,0.268213,546.275,557.46)">
        //         <circle cx="168" cy="2233" r="4256"/>
        //     </g>
        // </svg>
    )
}